<template>
    <div class="content-wrapper overflow-visible">
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Registrasi">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No SEP">{{rowReg.absd_no_sep||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div class="content">
            <validation-observer ref="VForm">
                <b-form @submit.prevent="doSubmit">
                    <div class="card">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">Laboratorium</h6>
                    </div>
                    <div class="card-body p-3">
                        <table class="table table-bordered mb-3">
                        <thead>
                            <tr class="table-light">
                            <th colspan="4" class="text-uppercase">Informasi Pemeriksaan</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>
                                <div class="result_tab">
                                <h4>Prioritas Pemeriksaan</h4>
                                <p v-if="dataDokter.aupdl_prioritas == 1">CITO<strong class="text-danger"></strong></p>
                                <p v-else>Non CITO</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <h4>Pasien Sedang Berpuasa</h4>
                                <p>{{dataDokter.aupdl_fasting=='Y'?'Ya':'Tidak'}}</p>
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                        <div class="req-list-resume mb-3">
                        <div class="row g-3">
                            <template v-for="(v,k) in row.auppl_data">
                            <div class="col-md-4 col-lg-3" :key="k+'labData'" v-if="isShowHeadLab(k)">
                                <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                    <h6 class="card-title text-uppercase font-weight-semibold">{{v.head||"-"}}</h6>
                                </div>
                                <div class="card-body py-2">
                                    <template v-for="v1,k1 in (v.dubData||[])" >
                                    <div class="req-list" v-if="isShowHeadSubHeadLab(k,k1)" :key="k1+'labdatas'">
                                        <h6 class="text-uppercase" v-if="v1.sub">{{v1.sub||"-"}}</h6>
                                        <template v-for="v2,k2 in (v1.data||[])">
                                        <div v-if="isShowLab(k,k1,k2)" :key="k2+'labsubdatas'">
                                            <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                            <span class="align-middle font-weight-semibold">{{v2.text||"-"}}</span>
                                            <span v-if="v2.notes">, {{v2.notes}}</span>
                                        </div>
                                        </template>
                                    </div>
                                    </template>
                                </div>
                                </div>
                            </div>
                            </template>
                            <div class="col-md-4 col-lg-3" v-if="row.auppl_hasil_lainnya">
                            <div class="card shadow-0 border">
                                <div class="card-header py-2 bg-light">
                                <h6 class="card-title text-uppercase font-weight-semibold">Item Pemeriksaan Lainnya</h6>
                                </div>
                                <div class="card-body py-2">
                                    <div class="req-list">
                                        <div>
                                        <i class="icon-checkmark-circle text-success align-middle mr-1"></i>
                                        <span class="align-middle">{{row.auppl_hasil_lainnya||"-"}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <span v-if="!selectedLabInput()" class="ml-3 mb-3"> Tidak ada Pemeriksaan</span>
                        </div>
                        <div class="text-center mt-3" v-if="canEditLab">
                            <a href="javascript:;" @click="doOpenLab" class="btn alpha-blue"
                            data-toggle="modal" data-target="#labItemsChecklist"><i
                                class="icon-plus2 mr-2"></i>Tambah Pemeriksaan Laboratorium</a>
                        </div>
                        </div>
                        <div class="card border mb-0">
                        <div @click="showPatient = !showPatient" class="card-header bg-info" style="cursor:pointer;" data-toggle="collapse" data-target="#infoPatient">
                            <div class="d-flex align-items-center justify-content-between">
                            <h6 class="card-title font-weight-semibold">Lihat Informasi Pasien</h6>
                            <i class="icon-chevron-down"></i>
                            </div>
                        </div>
                        <div v-if="showPatient" id="infoPatient">
                            <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered table-sm">
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>No. Rekam Medis</h4>
                                        <p>{{row.ap_code||"-"}} </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Nama Pasien</h4>
                                        <p>{{row.ap_fullname||"-"}} </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tgl. Lahir</h4>
                                        <p>{{row.ap_dob | moment("DD MMM YYYY")}}</p>
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Tinggi Badan</h4>
                                        <p v-if="dataDokter.auod_ttv_height">{{dataDokter.auod_ttv_height||"- "}}cm</p>
                                        <p v-else> - </p>
                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Berat Badan</h4>
                                        <p v-if="dataDokter.auod_ttv_weight">{{dataDokter.auod_ttv_weight||"- "}}cm</p>
                                        <p v-else> - </p>

                                    </div>
                                    </td>
                                    <td>
                                    <div class="result_tab">
                                        <h4>Luas Permukaan Badan</h4>
                                        <template v-if="dataDokter.auod_ttv_bmi || dataDokter.auod_ttv_luas_permukaan_anak">
                                            <p v-if="row.ap_usia > 15">{{dataDokter.auod_ttv_bmi||"- "}}m<sup>2</sup></p>
                                            <p v-else>{{dataDokter.auod_ttv_luas_permukaan_anak||"- "}}m<sup>2</sup></p>
                                        </template>
                                        <p v-else> - </p>

                                    </div>
                                    </td>
                                </tr>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="card">
                    <div class="card-header bg_head_panel">
                        <h6 class="card-title font-weight-semibold">UPLOAD HASIL LABORATORIUM</h6>
                    </div>
                    <div class="card-body p-3">
                        <div class="row">
                        <div class="col-md-12">
                            <table class="table table-bordered table-striped table-sm patient-table">
                            <thead>
                                <tr>
                                <th>Hasil Pemeriksaan </th>
                                <th>Upload Hasil</th>
                                <th>Nilai Kritis</th>
                                <th>Jam Keluar Hasil Kritis</th>
                                <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(v,k) in (row.auppl_hasil||[])" :key="k+'hasil'">
                                <td>
                                    <b-form-textarea v-model="row.auppl_hasil[k]['value']" type="text" name="name" class="form-control" />
                                <VValidate :name="'Hasil Pemeriksaan #'+(k+1)" v-model="row.auppl_hasil[k]['value']"
                                    :rules="{required:1, min:2}" />
                                </td>
                                <td>
                                    <Uploader v-model="row.auppl_hasil[k]['file']" isDocument type="docimagewithdocx" />
                                    <VValidate :name="'File #'+(k+1)" v-model="row.auppl_hasil[k]['file']"
                                        :rules="{required:1}" />
                                </td>
                                <td>
                                    <b-form-textarea v-model="row.auppl_hasil[k]['nilai_kritis']" type="text" name="name" class="form-control" />
                                </td>
                                <td>                            
                                    <div class="input-group">
                                        <div class="input-group-prepend"><span class="input-group-text"><i class="icon-alarm"></i></span></div>
                                        <vue-timepicker format="HH:mm" input-class="form-control" v-model="row.auppl_hasil[k]['jam_keluar']">
                                        </vue-timepicker>
                                    </div>
                                    <VValidate v-if="row.auppl_hasil[k]['nilai_kritis']" :name="'Jam Keluar Hasil #'+(k+1)" v-model="row.auppl_hasil[k]['jam_keluar']"
                                    :rules="{required:1}" />
                                <td> 
                                <a href="javascript:;" class="list-icons-item"
                                    @click="row.auppl_hasil.splice(k,1)"
                                    data-toggle="tooltip" data-placement="top" title="Delete"><i
                                    class="icon-bin"></i></a>
                                </td>
                                </tr>
                                <tr v-if="!(row.auppl_hasil||[]).length">
                                    <td colspan="99" class="text-center">Tidak Ada Data</td>
                                </tr>
                            </tbody>
                            </table>

                            <div class="more_data">
                            <a href="javascript:;" @click="addNew"><i class="icon-plus-circle2"></i>
                                Tambah</a>
                            </div>
                        </div>

                        </div>
                    </div>
                    <div class="card border shadow-0 mt-3" v-if="row.isEdit">
                        <div class="card-header bg-info">
                            <h5 class="card-title font-weight-semibold">Keterangan Perubahan</h5>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                            <label for="addInfoTindakan">Keterangan Perubahan</label>
                            <b-textarea v-model="row.aurm_notes" :formatter="$parent.normalText" rows="6" class="form-control" placeholder="Keterangan Perubahan">
                            </b-textarea>

                                
                            <VValidate 
                                :name="`Keterangan Perubahan`" 
                                v-model="row.aurm_notes" 
                                :rules="{required: 1, min: 2, max:512}"
                            />
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="text-right">
                            <button type="button" @click="back()" class="btn btn-light mr-3">Back</button>
                            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
                        </div>
                    </div>
                    </div>
                </b-form>
            </validation-observer>

            
            <b-modal v-model="openLab" :title="'Pilih Item Pemeriksaan Laboratorium'" size="xl" hide-footer>
                <div class="modal-header d-block p-0 mt-3">
                <ul class="nav nav-tabs mb-0 nav-tabs-bottom nav-justified">
                    <li v-for="(v,k) in row.auppl_data" :key="k" @click="changeTabLab(k)" class="nav-item">
                    <a href="javascript:;" data-toggle="tab"
                        :class="k == activeTabLab ? 'nav-link active' : 'nav-link'">{{v.head||"-"}}</a>
                    </li>
                </ul>
                </div>
                <div class="modal-body p-0 mt-3">
                <div class="tab-content">
                    <div v-for="(v,k) in row.auppl_data" :key="k+'labform'"
                    :class="k == activeTabLab ? 'tab-pane fade show active' : 'tab-pane fade'">
                    <template v-for="(v1,k1) in (v.dubData||[])">
                        <div :key="k1+'sss'">
                        <div v-if="v1.sub" class="selection-control font-weight-semibold bg-light text-uppercase">
                            {{v1.sub}}</div>
                        <div class="row sc-row g-0">
                            <template v-for="(v2,k2) in (v1.data||[])">
                            <div class="col-md-3" v-if="v2.id" :key="k2+'xdx'">
                                <div class="selection-control">
                                <b-form-checkbox :name="'tabLab'+String(v2.id)" v-model="v2['selected']">{{v2.text||"-"}}
                                </b-form-checkbox>
                                <b-form-input type="text" v-if="v2['selected']" v-model="v2['notes']" class="form-control form-control-sm d-block" placeholder="Catatan Permintaan" />
                                </div>
                            </div>
                            </template>
                        </div>
                        </div>
                    </template>
                    </div>
                </div>
                <div class="row ml-1 mt-2">
                    <div class="col-md-4">
                    <div class="form-group row">
                        <label>Lainnya</label>
                        <b-form-input v-model="row.auppl_hasil_lainnya" type="text" class="form-control" />
                    </div>
                    </div>
                </div>
                </div>
                <div class="modal-footer p-0 mt-3 bg-white flex-column justify-content-start align-items-start">
                <div class="font-weight-semibold m-0">Item Pemeriksaan Dipilih:</div>
                <span class="m-0" v-if="selectedLabInput()">{{selectedLabInput()||"-"}}</span>
                <span v-else> - </span>
                </div>
                <div class="modal-footer p-0 mt-3 bg-white">
                <div class="text-right">
                    <button @click="openLab = false" data-dismiss="modal" class="btn">Selesai</button>
                </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
const moment = require('moment')
import Gen from '@/libs/Gen.js'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
    extends: GlobalVue,
    data(){
        return {
            rowReg: {},
            dataDokter: {},
            showPatient: false,
            openLab: false,
            activeTabLab: 0,
        }
    },
    computed: {  
      canEditLab() {
        return true
      },
    },
    components:{ 
        VueTimepicker
    },
    methods: {
        back(){
            this.$router.back()
        },
        apiGet(params = {}, page = 1){
            if(!this.pageSlug || !this.$route.query.regId){
                this.$router.push({name : 'Dashboard'}).catch(()=>{})
            }

            if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
            this.data.data = false
            let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
            let url = this.$route.params.rmNo ? this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.rmNo : this.modulePage+'/'+this.$route.params.pageSlug
            
            Gen.apiRest(
                "/get/"+url, 
                {
                params: Object.assign({page: page}, paramsQuery, params.query||{})
                }
            ).then(res=>{
                this.loadingOverlay = false
                _.forEach(res.data, (v,k)=>{
                    this.$set(this, k, v)
                })
                this.pageNow = page
                this.last_page = this.data.last_page
                
                if(!this.isFound){
                    this.$router.push({name : 'Dashboard'}).catch(()=>{})
                }
            }).catch(()=>{
                this.loadingOverlay = false
            })

            // master
            if(this.$route.params.rmNo){
                Gen.apiRest(
                    "/get/"+url+'?master=1', 
                    {
                    params: Object.assign({page: page}, paramsQuery, params.query||{})
                    }
                ).then(res=>{
                    _.forEach(res.data, (v,k)=>{
                        this.$set(this, k, v)
                    })
                })
            }
            
        },
        initSticky(){
            const PageHeaderSticky = document.querySelector('.page-header-sticky')
            if(PageHeaderSticky){    
                const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
                if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                    PageHeaderToggle.addEventListener('click', function(e){
                    // e.preventDefault()
                    PageHeaderSticky.classList.toggle('page-header-sticky-open')
                    if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                        PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                    }else{
                        PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                        PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                        PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                    }
                    })    
                } 
            }
        },

        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = (master||[]).findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },

        doSubmit(){
            console.log("submit lab")
            if(this.row.aurm_is_active == 'N'){
                return this.$swal({
                    icon: 'error',
                    title: 'Data Telah tidak Aktif',
                    text: 'Silakan Kembali Ke Dashboard dan cari no Registrasi yang sama'
                })
            }   
            this.$refs['VForm'].validate().then(success=>{
                if(!success){
                    return this.$swal({
                        icon: 'error',
                        title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                         setTimeout(()=>{
                                let inv = []
                                let el = document.querySelectorAll('.label_error')
                                for(let i = 0; i < (el||[]).length; i++){
                                    if(el[i].style.display !== 'none'){
                                        inv.push(el[i].id)
                                    }
                                }
                                if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                            },500)
                        }
                    })
                }
                
                if(!(this.row.auppl_hasil||[]).length){
                    return this.$swal({
                        icon: 'error',
                        title: 'Tidak ada Hasil Lab, Mohon Lengkapi Data Terlebih Dahulu'
                    }).then(result => {
                        if (result.value) {
                            setTimeout(()=>{
                        let inv = []
                        let el = document.querySelectorAll('.label_error')
                        for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                        }
                        if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                    },500)
                        }
                    })
                }

                 if(success){
                    this.$swal({
                        icon: 'warning',
                        title: 'Apakah Anda Yakin akan menyimpan data ini?',
                        showCancelButton: true,
                        confirmButtonText: 'Ya',
                        cancelButtonText: 'Tidak, kembali'
                    }).then(result => {
                        if (result.value) {
                            let data = this.row
                            data.type = 'submit-data'
                            this.loadingOverlay = true
                            Gen.apiRest(
                                "/do/"+'UGDFormLab',
                                {data:data}, 
                                "POST"
                            ).then(res=>{
                                this.loadingOverlay = false
                                let resp = res.data
                                resp.statusType = 200
                                this.$swal({
                                    title: resp.message,
                                    icon: resp.status,
                                    confirmButtonText: 'Ok',
                                    allowOutsideClick: false,
                                    allowEscapeKey: false
                                }).then(result => {
                                    if (result.value) {
                                        if(this.user.levelId == 1){
                                            this.$router.push({ name: 'UGDAssesment', params: { pageSlug: this.row.aurm_pasien_id }, query: {regId: this.row.aurm_aur_id, byPassLevel: this.user.levelId == 1 ? this.uPerawat : null } }).catch(()=>{})
                                        }else{
                                            this.$router.push({ name: 'UGDAssesment', params: { pageSlug: this.row.aurm_pasien_id }, query: {regId: this.row.aurm_aur_id} }).catch(()=>{})
                                        }
                                    }
                                })
                            }).catch(err=>{
                                this.loadingOverlay = false
                                if(err){
                                    err.statusType = err.status
                                    err.status = "error"
                                    err.title = err.response?.data?.title
                                    err.message = err.response?.data?.message
                                    err.messageError = err.message
                                }
                                this.$parent.doSetAlertForm(err)
                            })
                        }
                    })
                }
            })

                

        },

        autoSave: _.debounce(function (data) {
            data.type = 'auto-save'
            data.aurm_last_step = this.row.aurm_last_step

            if(data.ap_usia <= 6){
                data.aukpn_skala_nyeri_value = this.hitungSkala
            }
            if(!data.isEdit){
                Gen.apiRest(
                    "/do/"+'UGDFormLab',
                    {data:data}, 
                    "POST"
                )
            }
        },1000),

        // copas dari sini
        changeTabLab(e) {
          this.activeTabLab = e
        },
        doOpenLab() {
          this.openLab = true
        },
        isShowHeadLab(i){
          let isData = 0
          for(let j = 0; j < (this.row.auppl_data[i]['dubData']||[]).length; j++){
            for(let k = 0; k < (this.row.auppl_data[i]['dubData'][j]['data']||[]).length; k++){  
              if(this.row.auppl_data[i]['dubData'][j]['data'][k]['selected']){
                isData += 1
              }
            }
          }
          return isData
        },
        isShowHeadSubHeadLab(i,j){
          let isData = 0
          for(let k = 0; k < (this.row.auppl_data[i]['dubData'][j]['data']||[]).length; k++){  
            if(this.row.auppl_data[i]['dubData'][j]['data'][k]['selected']){
              isData += 1
            }
          }
          return isData
        },
        isShowLab(i,j,k){
          let isData = 0
          if(this.row.auppl_data[i]['dubData'][j]['data'][k]['selected']){
            isData += 1
          }
          return isData
        },
        selectedLabInput(){
          let data = []
          for(let ik = 0; ik < (this.row.auppl_data||[]).length; ik++){
            for(let jk = 0; jk < (this.row.auppl_data[ik]['dubData']||[]).length; jk++){
              for(let kk = 0; kk < (this.row.auppl_data[ik]['dubData'][jk]['data']||[]).length; kk++){  
                  if(this.row.auppl_data[ik]['dubData'][jk]['data'][kk]['selected']){
                    data.push(this.row.auppl_data[ik]['dubData'][jk]['data'][kk]['text'])
                  }
              }
            }
          }
          if(this.row.auppl_hasil_lainnya){
            data.push(this.row.auppl_hasil_lainnya)
          }
          return data.join(", ")
        },

        addNew(){
            this.row.auppl_hasil.push({
                value: null,
                file: null,
                dokter:null,
                nilai_kritis: null,
                jam_keluar: '',
                dilaporkan_oleh: null,
                dilaporkan_pada: null
            })
        },
        toValidate(val){
            return {...val}
        },

        // copas dari ssini
    },
    mounted() {
        setTimeout(()=>{
            this.initSticky()
        },1000)
        document.body.classList.add('sidebar-xs')
        setTimeout(()=>{
            this.row.aurm_notes = null
        },1500)
        this.apiGet()
    },
    watch:{
        row: {
            handler(v) {
                this.autoSave(v)
            },
            deep: true
        },
    }
}
</script>
